import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FourColsWithFeaturesGrid = styled.div`
  ${tw`
    mt-12
    mb-8
    space-y-4
    sm:space-y-0
    sm:grid
    sm:grid-cols-2
    sm:col-gap-6
    sm:row-gap-12
    lg:grid-cols-4
    lg:col-gap-8
  `}
`

export const FourColsWithFeaturesSpace = styled.div`
  ${tw`
    flex
    space-x-3
  `}
`
