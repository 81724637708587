import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CallToActionSectionSection = styled.section`
  ${tw`
    py-20
    md:py-32
    bg-green-100
    overflow-hidden
  `}
`

export const CallToActionSectionWrapper = styled.div`
  ${tw`
    flex
    flex-wrap
    justify-between
  `}
`

export const CallToActionSectionFeatures = styled.div`
  ${tw`
    sm:grid
    sm:grid-cols-2
    md:mt-12
    md:w-1/2
    lg:mt-0
    lg:col-span-2
  `}
`
