import * as React from 'react'

function SvgPaintBrushIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.363.066c.681 0 1.277.226 1.788.679.51.452.766 1.019.766 1.7 0 .613-.219 1.347-.657 2.203-3.23 6.12-5.491 9.778-6.785 10.975-.944.885-2.005 1.328-3.182 1.328-1.225 0-2.279-.45-3.16-1.35-.88-.9-1.32-1.968-1.32-3.204 0-1.245.448-2.276 1.343-3.093l9.31-8.45C23.04.33 23.673.066 24.363.066zm-13.265 15.09a5.79 5.79 0 001.554 1.897 5.898 5.898 0 002.197 1.109l.014 1.036c.04 2.072-.59 3.76-1.89 5.064-1.298 1.303-2.994 1.955-5.085 1.955-1.197 0-2.257-.226-3.182-.678-.924-.453-1.666-1.073-2.225-1.861a8.667 8.667 0 01-1.262-2.67 11.697 11.697 0 01-.424-3.211c.068.049.268.194.599.438.33.243.632.46.904.65.273.189.56.366.861.532.302.165.526.248.672.248.399 0 .666-.18.802-.54.244-.642.523-1.19.84-1.642a5.25 5.25 0 011.014-1.109c.36-.287.788-.518 1.284-.693a7.89 7.89 0 011.503-.372 18.275 18.275 0 011.824-.153z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgPaintBrushIcon
