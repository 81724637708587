import styled from '@emotion/styled'
import tw from 'twin.macro'

export const onLeftStyle = tw`lg:row-start-1 lg:col-start-1 bg-red-500`
export const onRightStyle = tw`lg:row-start-1 lg:col-start-2 bg-blue-500`

export const ContentGrid = styled.div`
  ${tw`
    lg:grid
    lg:grid-cols-2
    lg:gap-8
    lg:my-16
  `}
`

export const ContentImage = styled.div`
  ${tw`
    mx-auto
    lg:mx-0
    lg:w-9/12
    relative
    mb-8
    lg:mb-0
    rounded-lg
    shadow-2xl
  `}
  height: fit-content;
  .on-left {
    row-start-1
    col-start-1
    lg:row-start-1
    lg:col-start-1
  }
  .on-right {
    row-start-1
    col-start-1
    lg:row-start-1
    lg:col-start-2
  }
`

export const ContentText = styled.div`
  ${tw`
    mx-auto
    lg:my-auto
    lg:max-w-none
    p-0
    lg:pr-28
  `}
  .on-left {
    lg:row-start-1
    lg:col-start-1
  }
  .on-left {
    lg:row-start-1
    lg:col-start-2
  }
  p.subhead {
    ${tw`
      mb-6
      text-xl
      leading-6
      font-semibold
    `}
    &.authentication {
      ${tw`
      text-blue-500
    `}
    }
  }
  p, li {
    ${tw`
      mb-5
      text-gray-700
    `}
    strong {
      ${tw`
        text-blue-400
      `}
    } 
    font-size: 1.25rem; 
    line-height: 2rem;
    letter-spacing: 0.015rem;
  }
  h3 {
    ${tw`
      text-purple-900
      font-bold
      mb-6
    `}
    font-size: 2rem;
  }
  li {
    ${tw`
      list-disc
      ml-5
    `}
  }
`
