import * as React from 'react'

function SvgDevicesIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={31}
      height={21}
      viewBox="0 0 31 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.698 4.052c0-.682.56-1.24 1.242-1.24H26.8c.683 0 1.242-.56 1.242-1.242 0-.683-.559-1.242-1.241-1.242H5.698a2.49 2.49 0 00-2.482 2.483v13.655h-.62a1.86 1.86 0 00-1.863 1.862 1.86 1.86 0 001.862 1.862h15.517v-3.724H5.698V4.052zm23.586 1.242h-7.448c-.683 0-1.241.558-1.241 1.241V18.95c0 .682.558 1.241 1.241 1.241h7.448c.683 0 1.242-.559 1.242-1.241V6.535c0-.683-.56-1.241-1.242-1.241zm-1.241 11.172h-4.966v-8.69h4.966v8.69z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgDevicesIcon
