import React, { ReactElement } from 'react'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import {
  HeroCallToActionsWrapper,
  HeroDescription,
  HeroHeader,
  HeroIntroStyle,
  HeroIntroTitle,
  HeroKicker,
  HeroValueProposition,
  HeroValuePropositionIcon,
  HeroValuePropositionItem,
  HeroValuePropositionText,
  HeroWrapper,
  ProductCategory,
} from './style'
import CallToAction from '../elements/call_to_action'
import Container from '../elements/container'
import SvgCheckIcon from '../svgs/icons/check_icon'

type HeroProps = {
  children: React.ReactNode
  title: RichTextBlock[]
  description: RichTextBlock[]
  call_to_action_color: string
  pageID: string
  category?: string
  icon?: ReactElement
  background_shape?: any
  value_propositions?: any
  call_to_action?: any
}

const Hero = ({
  children,
  title,
  description,
  call_to_action,
  call_to_action_color,
  pageID,
  category,
  icon,
  background_shape,
  value_propositions,
}: HeroProps) => (
  <HeroWrapper className={pageID}>
    {background_shape}
    <Container>
      {pageID !== 'home' && (
        <ProductCategory>
          <header>
            {icon}
            <h1 className={pageID}>{category}</h1>
          </header>
        </ProductCategory>
      )}
      <HeroHeader>
        <HeroIntroStyle className={pageID}>
          {pageID === 'home' && <HeroKicker>{category}</HeroKicker>}
          <HeroIntroTitle className={pageID}>{RichText.render(title)}</HeroIntroTitle>
          <HeroDescription className={pageID}>{RichText.render(description)}</HeroDescription>
          {value_propositions !== undefined && (
            <HeroValueProposition>
              {value_propositions.map(
                (value_proposition: { text: string }, index: string | number) => {
                  return (
                    <HeroValuePropositionItem key={index}>
                      <HeroValuePropositionIcon>
                        <SvgCheckIcon color="#7E5FFF" />
                      </HeroValuePropositionIcon>
                      <HeroValuePropositionText>
                        <p>{value_proposition.text}</p>
                      </HeroValuePropositionText>
                    </HeroValuePropositionItem>
                  )
                },
              )}
            </HeroValueProposition>
          )}
          <HeroCallToActionsWrapper>
            {call_to_action !== undefined && (
              <CallToAction>
                <a
                  href={call_to_action[1].call_to_action_path}
                  className={`white ${call_to_action_color}`}
                >
                  {call_to_action[1].call_to_action_text}
                </a>
              </CallToAction>
            )}
          </HeroCallToActionsWrapper>
        </HeroIntroStyle>
        {children}
      </HeroHeader>
    </Container>
  </HeroWrapper>
)

export default Hero
