import React from 'react'
import Container from '../elements/container'
import Feature from '../content_sections/feature'
import {
  CallToActionSectionFeatures,
  CallToActionSectionWrapper,
  CallToActionSectionSection,
} from './style'
import CtaSplitWithFeatures from './cta_split_with_features'
import { RichText } from 'prismic-reactjs'

const CallToActionSection = ({
  callToActionSectionData,
  iconCol1,
  iconCol2,
  showValueProposition,
}: any) => {
  const ctaDescription = callToActionSectionData.primary.cta_section_description
  if (!ctaDescription) return null
  return (
    <CallToActionSectionSection>
      <Container>
        <CallToActionSectionWrapper>
          <CtaSplitWithFeatures
            title={callToActionSectionData.primary.cta_section_title}
            description={RichText.render(ctaDescription.raw)}
            button_color_text={callToActionSectionData.primary.call_to_action_text_01}
            button_color_path={callToActionSectionData.primary.call_to_action_path_01}
            button_white_text={callToActionSectionData.primary.call_to_action_text_02}
            button_white_path={callToActionSectionData.primary.call_to_action_path_02}
          />
          {showValueProposition && (
            <CallToActionSectionFeatures>
              {callToActionSectionData.section_cta_items.map(
                (
                  feature: {
                    value_proposition_title: string
                    value_proposition_description: {
                      raw: any
                    }
                    call_to_action: string
                  },
                  index: number,
                ) => {
                  return (
                    <Feature
                      key={index}
                      className="fourth-fifths"
                      icon={index === 0 ? iconCol1 : iconCol2}
                      title={feature.value_proposition_title}
                      description={RichText.render(feature.value_proposition_description.raw)}
                      call_to_action={feature.call_to_action}
                    />
                  )
                },
              )}
            </CallToActionSectionFeatures>
          )}
        </CallToActionSectionWrapper>
      </Container>
    </CallToActionSectionSection>
  )
}

export default CallToActionSection
