import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CtaSplitWithFeaturesContent = styled.div`
  ${tw`
    md:w-2/5
  `}
  h2 {
    ${tw`
      mt-2
      text-3xl
      leading-tight
      md:leading-normal
      font-extrabold
      text-purple-900
    `}
  }
  p {
    ${tw`
      mt-4
      text-lg
      leading-7
      text-purple-300
    `}
  }
`

export const CtaSplitWithFeaturesButtons = styled.div`
  ${tw`
    space-x-4
    my-8
    flex
    flex-row
  `}
`
