import * as React from 'react'

function SvgCodeOffIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={24}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.592 11.26l-4.85-4.85a1.246 1.246 0 111.762-1.763l5.738 5.737a1.245 1.245 0 010 1.763l-3.6 3.6-1.763-1.775 2.713-2.713zM1.254 2.421l4.363 4.362-3.6 3.6a1.245 1.245 0 000 1.763l5.737 5.737a1.248 1.248 0 002.033-.404 1.246 1.246 0 00-.27-1.358l-4.85-4.863 2.712-2.712 15.088 15.087a1.246 1.246 0 001.762-1.762L3.017.647a1.245 1.245 0 00-1.763 0 1.26 1.26 0 000 1.775z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgCodeOffIcon
