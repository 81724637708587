import * as React from 'react'

function SvgTimerIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={27}
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.361.883a.817.817 0 01.817-.817h6.538a.817.817 0 110 1.635H8.178a.817.817 0 01-.817-.818zM19.538 3.219a.817.817 0 011.154 0l2.31 2.311a.816.816 0 01-.574 1.405.817.817 0 01-.58-.25l-2.31-2.31a.817.817 0 010-1.156zM22.888 14.776a11.441 11.441 0 11-22.882 0 11.441 11.441 0 0122.882 0zM10.63 7.421v8.172a.817.817 0 101.635 0V7.421a.817.817 0 10-1.635 0z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgTimerIcon
