import React from 'react'
import CallToActionSection from '../../components/call_to_action_section'
import FourColsWithFeatures from '../../components/four_cols_features'
import Hero from '../../components/hero'
// import { HeroIllustrationDevice } from '../../components/hero/hero_illustration'
// import LargeImage from '../../components/large_image'
import Layout from '../../components/layout'
import SectionWrapper from '../../components/sectionWrapper'
import SectionH2 from '../../components/section_h2'
import SectionH3Base from '../../components/section_h3_base'
import SvgCodeOffIcon from '../../components/svgs/icons/code_off_icon'
import SvgDevicesIcon from '../../components/svgs/icons/devices_icon'
import SvgPaintBrushIcon from '../../components/svgs/icons/paintbrush_icon'
import SvgUiEditorIcon from '../../components/svgs/icons/products/ui_editor_icon'
import SvgTimerIcon from '../../components/svgs/icons/timer_icon'

const featureIcons = [
  {
    feature01: <SvgPaintBrushIcon color="#7E5FFF" />,
  },
  {
    feature02: <SvgTimerIcon color="#7E5FFF" />,
  },
  {
    feature03: <SvgCodeOffIcon color="#7E5FFF" />,
  },
  {
    feature04: <SvgDevicesIcon color="#7E5FFF" />,
  },
]

const UIEditorPage = ({ pageContext: { data, localePath, lang, templateId } }: any) => {
  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <Hero
        icon={<SvgUiEditorIcon />}
        category={data.category}
        call_to_action_color={data.slug}
        pageID={data.slug}
        title={data.header_title.raw}
        description={data.header_description.raw}
        value_propositions={data.value_proposition}
        call_to_action={data.call_to_action}
      >
        {/* <HeroIllustrationDevice heroData={data} /> */}
      </Hero>
      <SectionWrapper color="light-blue" disabledPadding="padding-auto">
        <FourColsWithFeatures
          featureIcons={featureIcons}
          feature={data.body[0].items}
          slug={data.slug}
        />
      </SectionWrapper>
      <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[1].section_H2_primary.title}
          description={data.body[1].section_H2_primary.description.raw}
          textAlignment="text-left"
          color="on-dark-background"
          subhead={data.body[1].section_H2_primary.subhead}
          gridColumns={true}
        >
          {/* <LargeImage
            imageType={'desktop-image'}
            shadow={true}
            url={data.body[2].primary.image.url}
            alt={data.body[2].primary.image.alt}
          /> */}
        </SectionH2>
      </SectionWrapper>
      <SectionWrapper disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[3].section_H2_primary.title}
          description={data.body[3].section_H2_primary.description.raw}
          textAlignment="text-left"
          subhead={data.body[3].section_H2_primary.subhead}
          gridColumns={true}
        />
        <SectionH3Base
          slug={data.slug}
          title={data.body[4].h3_base_primary.title}
          description={data.body[4].h3_base_primary.description.raw}
          image={data.body[4].h3_base_primary.image}
          subhead={null}
          check_list={data.body[4].items}
          shadow={false}
        />
      </SectionWrapper>
      <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionH2
          slug={data.slug}
          title={data.body[5].section_H2_primary.title}
          description={data.body[5].section_H2_primary.description.raw}
          textAlignment="text-center"
          color="on-dark-background"
          subhead={null}
          gridColumns={false}
        >
          {/* <LargeImage
            imageType={'desktop-image'}
            shadow={false}
            url={data.body[6].primary.image.url}
            alt={data.body[6].primary.image.alt}
          /> */}
        </SectionH2>
      </SectionWrapper>
      <CallToActionSection callToActionSectionData={data.body[7]} />
    </Layout>
  )
}

export default UIEditorPage
