import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FeatureListWrapper = styled.div`
  ${tw`
    my-8
    lg:pr-28
  `}
`

export const FeatureList = styled.ul`
  ${tw`
    list-none
  `}
  li {
    ${tw`
      flex
      flex-row
      justify-start
      items-start
      space-x-3
      space-y-2
    `}
  }
`

export const FeatureIcon = styled.div`
  ${tw`
    flex
    items-center
    justify-center
    h-4
    w-4
    mt-3
    p-1
    rounded-full
    bg-indigo-200
  `}
  svg {
    ${tw`
      h-2
      w-3
    `}
  }
`

export const FeatureListText = styled.div`
  p {
    ${tw`
      text-base
      font-normal
      text-gray-700
    `}
  }
`
