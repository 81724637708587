import React from 'react'
import Feature from '../content_sections/feature'
import { FourColsWithFeaturesGrid } from './style'
import { RichText } from 'prismic-reactjs'

const getIcon = (index: number, featureIcons: any) => {
  if (index === 0) {
    return featureIcons[0].feature01
  }
  if (index === 1) {
    return featureIcons[1].feature02
  }
  if (index === 2) {
    return featureIcons[2].feature03
  }
  if (index === 3) {
    return featureIcons[3].feature04
  }
}

const FourColsWithFeatures = ({ feature, slug, featureIcons }: any) => (
  <FourColsWithFeaturesGrid>
    {feature.map((item: any, index: number) => {
      return (
        <Feature
          key={index}
          slug={slug}
          className=""
          title={item.title}
          description={RichText.render(item.description.raw)}
          icon={getIcon(index, featureIcons)}
        />
      )
    })}
  </FourColsWithFeaturesGrid>
)

export default FourColsWithFeatures
