import React from 'react'
import CallToAction from '../../elements/call_to_action'
import { CtaSplitWithFeaturesButtons, CtaSplitWithFeaturesContent } from './style'

const CtaSplitWithFeatures = ({
  title,
  description,
  button_color_text,
  button_white_text,
  button_color_path,
  button_white_path,
}: any) => {
  return (
    <CtaSplitWithFeaturesContent>
      <h2>{title}</h2>
      <div>{description}</div>
      <CtaSplitWithFeaturesButtons>
        {button_color_text !== 'Start free' && (
          <CallToAction>
            <a href={button_color_path} className="color home">
              {button_color_text}
            </a>
          </CallToAction>
        )}
        <CallToAction>
          <a href={button_white_path} className="white home">
            {button_white_text}
          </a>
        </CallToAction>
      </CtaSplitWithFeaturesButtons>
    </CtaSplitWithFeaturesContent>
  )
}

export default CtaSplitWithFeatures
