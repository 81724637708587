import React from 'react'
import { FeatureIcon, FeatureList, FeatureListText, FeatureListWrapper } from './style'
import SvgCheckIcon from '../svgs/icons/check_icon'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { ContentGrid, ContentText, ContentImage } from '../sections_h3/style'
import tw from 'twin.macro'

const Image = ({ url, alt, shadow }: { url: string; alt: string | undefined; shadow: boolean }) => {
  return (
    <ContentImage css={[shadow ? tw`shadow-2xl` : tw`shadow-none`]}>
      <img src={url} alt={alt} />
    </ContentImage>
  )
}

const Text = ({
  subhead,
  title,
  description,
  slug,
}: {
  subhead: string
  title: string
  description: RichTextBlock[]
  slug: string | null
}) => (
  <ContentText>
    <p className={`subhead ${slug}`}>{subhead}</p>
    <h3>{title}</h3>
    <div>{RichText.render(description)}</div>
  </ContentText>
)

const SectionH3Base = ({ data, title, subhead, description, check_list, image, shadow }: any) => {
  return (
    <ContentGrid>
      <Image url={image.url} alt={image.alt} shadow={shadow} />

      <div>
        <Text slug={null} subhead={subhead} title={title} description={description} />
        <FeatureListWrapper>
          {check_list.map((item: any, index: any) => {
            return (
              <FeatureList key={index}>
                <li>
                  <FeatureIcon>
                    <SvgCheckIcon color="#7126F2" />
                  </FeatureIcon>
                  <FeatureListText>
                    <p>{item.value_proposition_description}</p>
                  </FeatureListText>
                </li>
              </FeatureList>
            )
          })}
        </FeatureListWrapper>
      </div>
    </ContentGrid>
  )
}

export default SectionH3Base
